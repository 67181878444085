import request from '@/auth/jwt/request'

/**
 * @description 员工视频分类 -- 员工视频分类列表
 * @param {Object} param params {Object} 传值参数
 */
export const staffVideoCateListApi = params => { return request('staff-video-category/list', 'get', params) }

/**
 * @description 员工视频分类 -- 增加修改员工视频分类
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const staffVideoCateSaveApi = data => { return request('staff-video-category/save', 'post', {}, data) }

/**
 * @description 员工视频分类 -- 员工视频分类状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const staffVideoCateStatusApi = params => { return request('staff-video-category/status', 'get', params) }

/**
 * @description 员工视频分类 -- 员工视频分类详情
 * @param {Object} param params {Object} 传值参数
 */
export const staffVideoCateInfoApi = params => { return request('staff-video-category/info', 'get', params) }
/**
 * @description 员工视频分类 -- 删除员工视频分类
 * { id = x }
 */
export const staffVideoCateDeleteApi = data => { return request('staff-video-category/delete', 'delete', data) }